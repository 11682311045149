<template>
    <div class="background">
        <div class="contain">
            <div class="header">
                <div class="topbar">
                    <div style="flex-shrink: 1; background: transparent">
                        <img class="icon-stack" src="/images/exit.png" width="30px" @click="$bvModal.show('m-leave')" />
                    </div>
                    <div class="right-bar">
                        <img class="icon-stack" src="/images/history.png" width="30px" @click="getHistory" />
                        <img class="icon-stack" src="/images/trophy.png" width="30px" @click="getWinner" />
                        <img class="icon-stack" src="/images/faq.png" width="30px" @click="openFAQ" />
                        <img src="/images/holy_point.png" class="hw-logo" />
                        <div class="point-wrapper">
                            <div class="align-self-center font-weight-bold point">
                                {{ currentPoint }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="initStatus == 0" class="text-center mt-4">
                <b-spinner variant="light" />
            </div>
            <div v-else-if="initStatus == -1" class="text-center mt-4"> 
                <div class="text-light mb-2">Failed to load chest packages.</div>
                <b-button size="sm" variant="primary" @click="init">Retry</b-button>
            </div>
            <div v-if="initStatus == 1" class="content">
                <div class="text-center px-2 my-4">
                    <img src="/images/home_header_v2.png" style="width: 100%" />
                </div>
                <div class="separator"><div class="px-1">Available Chest Packages</div></div>
                <div class="small mb-3">Click one to play!</div>
                <div v-for="item in items" v-bind:key="item.id">
                    <img :src="item.image_url" class="banner-image" @click="goToPackage(item.id)" />
                    <div v-if="item.days_left <= 15" class="text-right finish-wrapper mr-2 mb-2">
                        <span class="small finish-box px-2">
                            Ending {{ item.days_left <= 0 ? "soon!" : "in " + item.days_left + " day(s)" }}
                        </span>
                    </div>
                </div>
                <div v-if="items.length == 0" class="mt-4">
                    No chest packages currently running.
                    <div class="small">Stay tuned for our upcoming, exciting chest packages!</div>
                </div>
            </div>
            <div v-if="initStatus == 1" class="text-center text-light small mb-1">
                Please read our FAQ before playing!
            </div>
        </div>
        <b-modal id="m-history" centered no-close-on-esc no-close-on-backdrop hide-footer scrollable title="My History"
            body-bg-variant="darkGrey" body-text-variant="light" header-bg-variant="darkGrey" header-text-variant="light" body-class="p-0"
            @close="removeModalHash"
        >
            <div v-if="isFetching" class="text-center p-1r">
                <b-spinner variant="light" />
            </div>
            <div v-else-if="errorMessage != ''" class="p-1r">
                <div>{{ errorMessage }}</div>
            </div>
            <div v-else>
                <div class="p-1r">Showing your last 100 plays</div>
                <div v-for="history in histories" v-bind:key="history.id">
                    <hr class="my-0 hr-divider" />
                    <div class="history-flex text-light p-1r">
                        <div class="history-item">
                            {{ history.package_name }}
                            <div class="text-darkGrey small">{{ history.package_name }} Chest(s) - {{ history.point_used }} points</div>
                            <div class="small" v-html="history.rewardHtml"></div>
                        </div>
                        <div class="history-item text-right small">
                            {{ $helper.parseDate(history.created_at, true) }}
                            <div class="text-darkGrey">{{ $helper.parseHourMinute(history.created_at, true) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="m-winner" centered no-close-on-esc no-close-on-backdrop hide-footer scrollable title="Latest Lucky Winners"
            body-bg-variant="darkGrey" body-text-variant="light" header-bg-variant="darkGrey" header-text-variant="light" body-class="p-0"
            @close="removeModalHash"
        >
            <div v-if="isFetching" class="text-center p-1r">
                <b-spinner variant="light" />
            </div>
            <div v-else-if="errorMessage != ''" class="p-1r">
                <div>{{ errorMessage }}</div>
            </div>
            <div v-else>
                <div class="p-1r">Showing the latest 25 lucky winners</div>
                <div v-for="(winner, index) in winners" v-bind:key="index">
                    <hr class="my-0 hr-divider" />
                    <div class="d-flex flex-row p-1r">
                        <div style="width: 76px; height: 100%" :class="'bg-reward bg-' + winner.textCss">
                            <div :class="'bg-inner-reward bg-inner-' + winner.textCss" style="width: 100%">
                                <img :src="winner.reward_image" style="width: 60px">
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center ml-3">
                            <div class="font-weight-bold">{{ winner.user_fullname }}</div>
                            <div class="small text-darkGrey">won <span :class="'font-weight-bold reward-' + winner.textCss">{{ winner.reward_name }}</span></div>
                            <div class="small text-darkGrey">from <strong>{{ winner.holy_chest_name }}</strong></div>
                            <div class="small text-darkGrey">at {{ $helper.parseDate(winner.datetime, true) }}, {{ $helper.parseHourMinute(winner.datetime) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="m-faq" centered title="Holywings Chest FAQ" hide-footer
            body-bg-variant="darkGrey" body-text-variant="light"
            header-bg-variant="darkGrey" header-text-variant="light"
            @close="removeModalHash"
        >
            <div class="font-weight-bold">What is Holywings Chest?</div>
            <div class="text-darkGrey">
                Holywings Chest is a simple mini-game of testing your luck.
                <div class="mt-4">You will open treasure chests, which may reveal either a reward (yay!) or a bust (a.k.a. Zonk).</div>
            </div>
            <div class="mt-4 font-weight-bold">I am confident with my luck, how do I play?</div>
            <div class="text-darkGrey">
                Select any available chest packages that sparks your interest in the front menu. In any chest packages, we will show you all available rewards that you may win in that chest package.
                <div class="mt-4">
                    Happy with the rewards? Spend your Holypoints to open a treasure chest and challenge the chances! You may also open in multiples of 10, 25, 50, or even a hundred treasure chests in a single play.
                </div>
                <div class="mt-4">
                    After confirmation, a treasure chest will appear in the screen. Click on it, fingers crossed, and the treasure chest will reveal its content! 
                </div>
                <div class="mt-4">
                    Repeat the cycle if you’re playing with multiple treasure chests, or simply click Skip if you want to immediately open all treasure chests and look at all the rewards you won at that play.
                </div>
            </div>
            <div class="mt-4 font-weight-bold">YAAAAA I WON A REWARD, how do I claim it?</div>
            <div class="text-darkGrey">
                Congratulations! Each reward you won will be automatically issued to your Holywings account in the form of vouchers. You may claim it whenever you visit any Holywings outlet. 
                <div class="mt-4">
                    <span class="font-weight-bold text-light">Do mind the voucher’s expiration date, though! Vouchers expire 30 days after you won it!</span> You do not want to waste your hard-earned luck!
                </div>
                <div class="mt-4">
                    In addition, if you won a Silver or Gold reward, you’ve earned yourself a temporary spot in our Lucky Winner’s List, which showcases our latest 25 lucky winners! Talk about some bragging rights!
                </div>
            </div>
        </b-modal>
        <b-modal id="m-leave" centered no-close-on-esc no-close-on-backdrop hide-header hide-footer 
            body-bg-variant="darkGrey" body-text-variant="light"
        >
            <div class="text-center">Leave Holywings Chest?</div>
            <div class="mt-4 d-flex flex-row justify-content-between">
                <b-button class="ml-4" @click="$bvModal.hide('m-leave')">
                    Cancel
                </b-button>
                <b-button class="mr-4" variant="success" @click="leave">
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {

            initStatus: -1,
            isFetching: false,
            errorMessage: "",

            modalOpened: "",
        
            currentPoint: 0,
            items: [],
            histories: [],
            winners: []

        }
    },
    watch: {

        $route(to, from) {
            
            if(to.hash == "" && from.hash == "#modal") {
                this.$bvModal.hide(this.modalOpened)
                this.modalOpened = ""
            }

        }

    },
    methods: {

        init() {

            this.initStatus = 0

            let getPackages = this.$axios.post("api/v1/holychest/get-active-chest-package").then(res => {
                this.items = res.data.data
            })

            Promise.all([getPackages]).then(res => {
                this.initStatus = 1
            })
            .catch(error => {
                this.initStatus = -1
            })

        },

        getHistory() {

            this.isFetching = true
            this.errorMessage = ""
            this.$bvModal.show("m-history")

            window.location.hash = "modal"
            this.modalOpened = "m-history"

            this.$axios.post("api/v1/holychest/history").then(res => {

                res.data.data.forEach(it => {

                    let rewardHtmls = []

                    it.rewards.sort((a, b) => a.t - b.t)
                    it.rewards.forEach(rwd => {

                        // Konsolidasi warna item
                        let tierCss = "reward-gold font-weight-bold"
                        if(rwd.grade == 2) {
                            tierCss = "reward-silver"
                        }
                        else if(rwd.grade == 3) {
                            tierCss = "reward-bronze"
                        }

                        rewardHtmls.push(`<span class='${tierCss}'>${rwd.quantity}x ${rwd.title}</span>`)

                    })

                    it.rewardHtml = rewardHtmls.join(", ")
                    if(rewardHtmls.length == 0) {
                        it.rewardHtml = "<span class='text-darkGrey'>-</span>"
                    }
                    
                })

                this.histories = res.data.data

            })
            .catch(error => {
                this.errorMessage = "Failed to retrieve history data, please try again later!"
            })
            .finally(() => this.isFetching = false)

        },

        getWinner() {

            this.isFetching = true
            this.errorMessage = ""
            this.$bvModal.show("m-winner")

            window.location.hash = "modal"
            this.modalOpened = "m-winner"

            this.$axios.post("api/v1/holychest/history-winners").then(res => {
                
                res.data.data.forEach(it => {

                    // Konsolidasi warna item
                    let tierCss = "gold"
                    if (it.grade == 2) {
                        tierCss = "silver"
                    }

                    it.textCss = tierCss
                    
                })

                this.winners = res.data.data

            })
            .catch(error => {
                this.errorMessage = "Failed to retrieve winners data, please try again later!"
            })
            .finally(() => this.isFetching = false)

        },

        openFAQ() {

            this.$bvModal.show('m-faq')

            window.location.hash = "modal"
            this.modalOpened = "m-faq"

        },

        goToPackage(id) {
            this.$router.push({ path: '/package/' + id })
        },

        leave() {
            window.location = "https://pintukeluar"
        },

        removeModalHash() {
            this.$router.go(-1)
        }

    },
    created() {

        // Cek apabila butuh refresh
        if(localStorage.getItem("force-refresh") == "true") {
            location.reload()
            localStorage.removeItem("force-refresh")
        }

        this.currentPoint = localStorage.getItem("point")
        this.init()

    }
}
</script>

<style scoped>

    .p-1r {
        padding: 1rem;
    }

    .hr-divider {
        border: 1px solid rgba(122, 122, 122, 0.2);
    }

    .icon-stack {
        height: 30px;
        width: 30px;
        margin-right: 12px;
    }

    .banner-item {
        margin-top: 8px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.75);
    }

    .banner-image {
        border-radius: 5px;
        width: 100%;
        margin-bottom: 8px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.75);
    }

    .history-flex {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        align-items: flex-start;
    }

    .history-item:nth-child(1) {
        flex: 0 1 auto;
        align-self: auto;
    }

    .history-item:nth-child(2) {
        flex: 1 0 auto;
        align-self: auto;
    }

    .separator {
        display: flex;
        align-items: center;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    .separator::before, .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid rgb(255, 255, 255);
    }

    .separator:not(:empty)::before {
        margin-right: .25em;
    }

    .separator:not(:empty)::after {
        margin-left: .25em;
    }

    .finish-wrapper {
        margin-top: -22px;
    }

    .finish-box {
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid white;
        border-radius: 5px;
        color: black;
    }

</style>
